import { render, staticRenderFns } from "./HomePrivileges.vue?vue&type=template&id=df235e38&scoped=true"
import script from "./HomePrivileges.vue?vue&type=script&lang=js"
export * from "./HomePrivileges.vue?vue&type=script&lang=js"
import style0 from "./HomePrivileges.vue?vue&type=style&index=0&id=df235e38&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df235e38",
  null
  
)

export default component.exports